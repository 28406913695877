import React, { useEffect } from 'react'
import { useField } from 'formular'

import Checkbox, { CheckboxProps } from '../Checkbox/Checkbox'


export type CheckboxViewProps = Omit<CheckboxProps, 'field'> & {
  value: boolean
  onChange: () => void
}

const CheckboxView: React.FC<CheckboxViewProps> = (props) => {
  const { onChange } = props

  const field = useField({
    value: props.value,
  })

  useEffect(() => {
    if (field.state.value !== props.value) {
      field.set(props.value)
    }
  }, [ field, props.value ])

  useEffect(() => {
    field.on('change', onChange)

    return () => {
      field.off('change', onChange)
    }
  }, [ field, onChange ])

  return (
    <Checkbox
      field={field}
      {...props}
    />
  )
}


export default CheckboxView
