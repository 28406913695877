import React, { useState, useCallback, useRef, useEffect, MouseEventHandler } from 'react'
import { Field, FieldState, useField, useFieldState } from 'formular'
import { useUniqueId } from 'hooks'
import cx from 'classnames'
import { debounce as handleDebounce } from 'helpers'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import type { GlobalHtmlAttributes } from 'helpers/getters'

import masks from '../Input/util/masks'

import s from '../Input/Input.scss'


export const styles = [ 'light', 'dark' ] as const

export type InputStyle = typeof styles[number]

export type SimpleInputProps = Omit<GlobalHtmlAttributes<'HTMLInputElement'>, 'data-testid'> & {
  className?: string
  id?: string
  mask?: 'time' | 'date' | 'ipRange' | 'year' | 'year_1800_1990' | 'year_1900_now' | 'capitalize' | 'phone' | 'price' | 'number'
  style?: InputStyle
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value: any) => void
  debounce?: number
  loading?: boolean
  value: string
}

const SimpleInput: React.FC<SimpleInputProps> = (props) => {
  const {
    className, id, style = 'light', mask, disabled,
    onFocus, onBlur, onChange, loading, debounce, value,
    ...otherProps
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [ isFocused, setFocusedState ] = useState(Boolean(otherProps.autoFocus))

  const field = useField({
    value,
  })

  useEffect(() => {
    field.set(value)
  }, [ field, value ])

  useEffect(() => {
    if (!otherProps.autoFocus) {
      return () => {
        setFocusedState(true)
      }
    }
  }, [ otherProps.autoFocus ])

  const handleFocus = useCallback((event) => {
    setFocusedState(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ])

  const handleBlur = useCallback(async (event) => {
    setFocusedState(false)

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ onBlur ])

  const handleChange = useCallback((event) => {
    let value = event.target.value

    const applyMask = masks[mask]

    if (typeof applyMask === 'function') {
      value = applyMask(value)
    }

    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, mask, onChange ])

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus()
    }
  }, [ isFocused ])

  const inputAreaClassName = cx(s.inputArea,
    'flex radius-8',
    s[`style-${style}`], {
      [s.focused]: isFocused,
      [s.disabled]: disabled || loading,
    })

  const uniqueId = useUniqueId('input-')
  const controlId = id || uniqueId
  const htmlAttrs = getGlobalHtmlAttrs(otherProps)

  return (
    <div className={cx(className, inputAreaClassName)}>
      <FieldState field={field}>
        {
          ({ value }) => (
            <input
              {...htmlAttrs}
              id={controlId}
              className={s.input}
              ref={inputRef}
              style={{ background: 'none' }}
              //@ts-ignore
              value={loading ? '' : value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={debounce ? handleDebounce(handleChange, debounce) : handleChange}
              disabled={disabled || loading}
            />
          )
        }
      </FieldState>
    </div>
  )
}


export default SimpleInput
