import React, { useCallback, useMemo } from 'react'
import { Field, useField, useFieldState } from 'formular'

import { required } from 'helpers/validators'

import { Attachment, IconName } from 'components/dataDisplay'
import { DropdownOption, Input, Knob } from 'components/inputs'

import Dropdown from '../Dropdown/Dropdown'


type DropdownWithAttachmentProps = { // TODO add validation and mask
  className?: string
  field: Field<Array<string | number>>
  options: DropdownOption[]
  label: string
  attachmentIcon: IconName
  maxItems?: number
  withSearch?: boolean
}

const DropdownWithAttachment: React.FC<DropdownWithAttachmentProps> = (props) => {
  const {
    className,
    field,
    label,
    attachmentIcon,
    options,
    maxItems,
    withSearch,
  } = props

  const { value } = useFieldState(field)

  const currentOptions = useMemo(() => (
    options.filter((option) => !value.includes(option.value))
  ), [ value, options ])

  const dropdownField = useField<string | number>({
    value: null,
    validate: [ required ],
  })

  const handleAdd = useCallback((optionValue) => {
    dropdownField.validate().then(() => {
      if (dropdownField.state.isValid) {
        field.set([ ...value, options.find((option) => option.value === optionValue).value ])
        dropdownField.set(null)
      }
    })
  }, [ dropdownField, field, value, options ])

  const handleDelete = useCallback((optionValue) => {
    field.set(value.filter((value) => value !== optionValue))
  }, [ field, value ])

  return (
    <div className={className}>
      <div className="flex justify-between">
        <Dropdown
          className="w-full"
          options={currentOptions}
          field={dropdownField}
          label={label}
          withSearch={withSearch}
        />
        <Knob
          className="ml-8px no-shrink"
          size={40}
          style="primary"
          icon="main/add_20"
          disabled={value.length >= maxItems}
          onClick={() => handleAdd(dropdownField.state.value)}
        />
      </div>
      {
        value.map((optionValue) => {
          const activeOption = options.find((option) => option.value === optionValue)

          if (!activeOption) {
            return null
          }

          return (
            <Attachment
              key={optionValue}
              className="mt-16px pr-48px"
              icon={attachmentIcon}
              title={activeOption.title}
              text={activeOption.subTitle}
              onDeleteClick={() => handleDelete(optionValue)}
            />
          )
        })
      }
    </div>
  )
}

export default DropdownWithAttachment